const SHOPIFY_URL = process.env.GATSBY_SHOPIFY_URL;
const SHOPIFY_ACCESS_TOKEN = process.env.GATSBY_SHOPIFY_ACCESS_TOKEN;
const SHOPIFY_API_VERSION = "2023-04";
const SHOPIFY_GRAPHQL_URL = `${SHOPIFY_URL}/api/${SHOPIFY_API_VERSION}/graphql.json`;

const fetchShopifyGraphql = async (query, variables = {}) => {
  const response = await fetch(SHOPIFY_GRAPHQL_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Shopify-Storefront-Access-Token": SHOPIFY_ACCESS_TOKEN,
    },
    body: JSON.stringify({ query, variables }),
  });
  if (!response.ok)
    throw new Error(
      `Error ${response.status} ${response.statusText} calling ${SHOPIFY_GRAPHQL_URL}`
    );
  const result = await response.json();
  if (result.errors?.length) {
    throw new Error(`Graphql error - ${result.errors.map((item) => item.message).join(" - ")}`);
  }
  const { data } = result;
  return data;
};

export const hasShopify = !!SHOPIFY_URL;

const normalizeProduct = (shopifyProduct) => {
  const price = shopifyProduct?.node?.price;
  const compareAtPrice = shopifyProduct?.node?.compareAtPrice;
  const compareAtPriceValue = parseFloat(compareAtPrice?.amount);
  const priceValue = parseFloat(price?.amount);
  const onlineStoreUrlId = shopifyProduct?.node?.id.split("/").slice(-1)?.[0];

  return {
    id: shopifyProduct?.node?.id || shopifyProduct.id,
    sku: shopifyProduct?.node.sku,
    name: shopifyProduct.title,
    descriptionHtml: shopifyProduct.descriptionHtml,
    availableForSale: shopifyProduct?.node?.availableForSale, // TODO check onlineStoreUrl
    msrpPrice:
      compareAtPriceValue && compareAtPrice?.currencyCode
        ? {
            value: compareAtPriceValue,
            currency: compareAtPrice.currencyCode,
          }
        : null, //MSRP
    sellingPrice:
      priceValue && price?.currencyCode
        ? {
            value: priceValue,
            currency: price.currencyCode,
          }
        : null, // SELLING PRICE

    shopLink: `${shopifyProduct.onlineStoreUrl}?variant=${onlineStoreUrlId}`,
    contributoRaee: shopifyProduct?.contributoRaee,
    omnibusPrice: shopifyProduct.node?.omnibusPrice, // OMNIBUS PRICE
    repairabilityIndex: shopifyProduct?.repairabilityIndex,
    pdfRepairabilityLink: shopifyProduct?.shop?.primaryDomain?.url
      ? `${shopifyProduct.shop.primaryDomain.url}/cdn/shop/files/${shopifyProduct.variants.nodes[0].sku}.pdf`
      : null,
  };
};

const splitChunks = (arr, size) =>
  Array.from({ length: Math.ceil(arr.length / size) }, (_v, i) =>
    arr.slice(i * size, i * size + size)
  );

export const queryProductsDetails = async (productIds) => {
  const pageSize = 15;
  const chunks = splitChunks(productIds, pageSize);
  const products = [];
  for (const chunk of chunks) {
    const res = await fetchShopifyGraphql(
      `query ProductsQuery($first: Int, $query: String){
        products(first: $first, query: $query) {
          nodes {
            id
            title
            availableForSale
            variants(first: 30) {
              nodes {
                sku
                id
                omnibusPrice: metafields(identifiers: { namespace: "app--320503809--omnibus", key: "price" }) {
                  value
                }
                price {
                  amount
                  currencyCode
                }
                compareAtPrice {
                  amount
                  currencyCode
                }
                availableForSale
              }
            }
            descriptionHtml
            onlineStoreUrl
            contributoRaee: metafields(identifiers: { namespace: "tax", key: "eco_tax" }) {
              value
            }
            repairabilityIndex: metafields(identifiers: { namespace: "custom", key: "repairability_index" }) {
              value
            }
          }
          pageInfo {
            hasNextPage
            startCursor
          }
        }
        shop {
          primaryDomain {
              url
          }
        }
    }`,
      {
        query: `nodes:sku:${chunk.join(" OR ")} AND tag:is_product`,
        first: chunk.length,
      }
    );
    if (res.products?.nodes?.length) {
      products.push(
        ...res.products.nodes
          .map(
            (item) =>
              item?.variants?.nodes?.map((node) => ({ ...item, shop: res.shop, node })) || []
          )
          .flat()
      );
    }
  }
  return products
    .filter((product) => productIds.includes(product.node.sku))
    .map((item) => normalizeProduct(item));
};
